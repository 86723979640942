import './card.css';
import {ReactNode} from "react";

export default function Card({children}: {children: ReactNode}) {
    return (
        <div className="row">
            <div className="leftcolumn">
                <div className="card">
                    {children}
                </div>
            </div>
        </div>
    );
}
