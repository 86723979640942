// @ts-ignore
import Markdown from "../../components/Markdown/Markdown.tsx";
// @ts-ignore
import Header from "../../components/Header/Header.tsx";
// @ts-ignore
import React, { useEffect, useState } from "react";
// @ts-ignore
import {instruction} from "../../api/instruction.ts";
// @ts-ignore
import Card from "../../components/Card/Card.tsx";

export default () => {

    const [markdownText, setMarkdownText] = useState<string>("");

    useEffect(() => {
        const fetchInstruction = async () => {
            try {
                const result = await instruction();
                setMarkdownText(result);
            } catch (error) {
                console.error("Ошибка загрузки инструкции:", error);
            }
        };

        fetchInstruction().catch((error) => {
            console.error("Ошибка при вызове fetchInstruction:", error);
        });
    }, []);
    return (
        <>
            <Header name="Документация" />
            <Card>
                <Markdown markdownText={markdownText}></Markdown>
            </Card>
        </>
    );
}
