import './App.css';
// @ts-ignore
import SearchPage from "./pages/SearchPage/SearchPage.tsx";
// @ts-ignore
import DocsPage from "./pages/DocsPage/DocsPage.tsx";
// @ts-ignore
import Navigation from "./components/Navigation/Navigation.tsx";
// @ts-ignore
import Item from "./components/Navigation/Item.tsx";
// @ts-ignore
import Loader from "./components/Loader/Loader.tsx";
// @ts-ignore
import Footer from "./components/Footer/Footer.tsx";
// @ts-ignore
import React, { useState } from 'react';
// @ts-ignore
import Content from "./components/Content/Content.tsx";

export default function App() {
    const [loader, setLoader] = useState(false);
    const [route, setRoute] = useState(<SearchPage setLoader={setLoader} />);

    return (
        <>
            {
                loader ? <Loader /> : <></>
            }
            <Navigation>
                <Item name="Главная" onClick={() => setRoute(<SearchPage setLoader={setLoader} />)}/>
                <Item name="Документация" onClick={() => setRoute(<DocsPage />)}/>
            </Navigation>
            <Content>
                {route}
            </Content>
            <Footer/>
        </>
    );
}
