import './input.css'

export default function Input(
    {value, name, key, type, onChange}: {
        key?: string;
        name?: string;
        onChange?: (e) => void;
        type?: string;
        value?: string
    },
) {
    return (
        <div className="text-field">
            <label className="text-field__label" htmlFor={key}>{name}</label>
            <input className="text-field__input" type={type} name={key} id={key} placeholder={name}
                   value={value} onChange={onChange} />
        </div>
    )
    ;
}
