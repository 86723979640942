export default function Item(
    {name, active, onClick}: {name: string; active?: boolean; onClick?: () => void}
) {
    const className = (): string => {
        let classNameList = [];

        if (active) {
            classNameList.push('active');
        }

        return classNameList.join(' ');
    };

    return (
        <li>
            <a
                className={className()}
                onClick={onClick}
            >
                {name}
            </a>
        </li>
    );
}
