// @ts-ignore
import React from 'react';
import ReactMarkdown from 'react-markdown';

export default ({ markdownText }) => {
    return (
        <div>
            <ReactMarkdown>{markdownText}</ReactMarkdown>
        </div>
    );
};