// @ts-ignore
import { ReactComponent as CheckedIcon} from '../../icons/CheckedIcon.svg';
// @ts-ignore
import { ReactComponent as XIcon} from '../../icons/XIcon.svg';

interface ValidateProps {
    ok?: boolean;
    result: {
        email: string;
    };
}

interface ValidationComponentProps {
    validate: ValidateProps;
}

export default ({validate}: ValidationComponentProps) => {
    if (validate.ok == undefined) {
        return <></>;
    }

    const messages = {
        0: `Почта ${validate.result.email} не прошла проверку (Отправка на данную почту запрещена)`,
        1: `Почта ${validate.result.email} прошла проверку успешно`
    }

    const icons = {
        0: <XIcon color='#f44336'/>,
        1: <CheckedIcon color='#04AA6D'/>
    }

    return (
        <div>
            <br/>
            {icons[Number(validate.ok)]} {messages[Number(validate.ok)]}
        </div>
    )
}
