import './navigation.css'
// @ts-ignore
import React from "react";

export default function Navigation({children}: {children: React.ReactNode}) {
    return (
        <ul>
            {children}
        </ul>
    );
}
