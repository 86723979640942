import './footer.css'
// @ts-ignore
import { ReactComponent as TelegramIcon} from '../../icons/TelegramIcon.svg';

export default function Footer() {
    return (
        <div className="footer">
            <p>© 2024 romtuck</p>
            <a href="https://t.me/romtuck"><TelegramIcon color='#1c93e3'/></a>
        </div>
    );
}
